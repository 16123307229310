import { createTheme } from "@mui/material/styles";

const AppTheme = createTheme({
    palette: {
        primary: {
            main: "#ffce00",
        },
        secondary: {
            main: "#1c0647",
        },
    },
});

export default AppTheme;
