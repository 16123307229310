import React from "react";
import {Typography, Box, Link} from '@mui/material';

function About() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '2em',
            }}
        >
            <Typography color='primary' variant="h2">About</Typography>
            <Typography color='primary' variant="body1" marginTop="2em">
                I have been playing DotA off and on since late 2003, when the game was still
                a custom Warcraft III map. There have been several hiatuses along the way for
                all of life's many adventures, but I am sure I will keep coming back to DotA
                for many years to come. A special thanks to all the amazing community members
                of RD2L, where I have met most of my DotA friends. I hope that you find something
                useful or maybe entertaining out of this small project.
            </Typography>
            <Typography color='primary' variant="body1" marginTop="2em">
                From time to time, you can find me streaming DotA and occasionally some other
                content on Twitch at:{" "}
                <Link href='https://www.twitch.tv/viragedota' target='_blank' rel='noopener noreferrer'>
                    https://www.twitch.tv/viragedota
                </Link>
            </Typography>
            <Typography color='primary' variant='h4' marginTop='2em'>
                Stalk My DotA Stats
            </Typography>
            <Typography color='primary' variant="body1" marginTop="2em">
                <Link href='https://www.dotabuff.com/players/33459634' target='_blank' rel='noopener noreferrer'>
                    https://www.dotabuff.com/players/33459634
                </Link>
            </Typography>
            <Typography color='primary' variant="body1" marginTop="2em">
                <Link href='https://www.opendota.com/players/33459634' target='_blank' rel='noopener noreferrer'>
                    https://www.opendota.com/players/33459634
                </Link>
            </Typography>
            <Typography color='primary' variant="body1" marginTop="2em">
                <Link href='https://www.stratz.com/players/33459634' target='_blank' rel='noopener noreferrer'>
                    https://www.stratz.com/players/33459634
                </Link>
            </Typography>
        </Box>
    )
}

export default About;
