import React from 'react';
import Button from '@mui/material/Button';

const LogoutButton = ({onLogout}) => {

    const handleLogout = () => {
        fetch(process.env.REACT_APP_API_URL + '/logout', {
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Logout failed');
                }
                onLogout();
            })
            .catch(error => {
                console.error('Logout failed:', error);
            });
    };

    return <Button variant="contained" color="primary" onClick={handleLogout}>Log Out</Button>;
};

export default LogoutButton;
