import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardContent, Container, Typography, Box} from '@mui/material';
import DOMPurify from 'dompurify';

function BlogPost(props) {
    const [post, setPost] = useState({});

    let {id} = useParams();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/blog/${id}`)
            .then(response => response.json())
            .then(data => {
                // Define a configuration for DOMPurify
                const config = {
                    ADD_TAGS: ["iframe"],
                    ADD_ATTR: ["src", "allowfullscreen", "frameborder", "scrolling"],
                    ALLOWED_URI_REGEXP: /^(?:(?:https?:)?\/\/(?:www\.)?(?:youtube\.com|imgur\.com|giphy\.com)[^\s]*$)?/gi
                };

                // Sanitize the data content using the configuration
                data.content = DOMPurify.sanitize(data.content, config);

                // Update the post state
                setPost(data);
            })
            .catch(err => console.error(err));
    }, [id]);

    return (
        <Container>
            <Box sx={{boxShadow: 1, p: 2, mt: 2, mb: 2}}>
                <Card>
                    <CardContent>
                        <Typography variant='h3' gutterBottom>
                            {post.title}
                        </Typography>
                        <Typography variant='body2' color='text.secondary' gutterBottom>
                            <em>Author: {post.author}</em>
                        </Typography>
                        <Typography variant='body2' color='text.secondary' gutterBottom>
                            <em>Posted: {post.date}</em>
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
}

export default BlogPost;
