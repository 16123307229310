import React, {useState, useEffect, useContext} from 'react';
import AppStateContext from "./appStateContext";
import {Button, TextField, Box} from '@mui/material';

function CreatePlayer() {
    const [name, setName] = useState('');
    const [steam_id, setSteam_id] = useState('');
    const [appState, setAppState] = useContext(AppStateContext);

    useEffect(() => {
        if (!appState.is_authenticated || !appState.user_roles.includes('Admin')) {
            window.location.href = "/";
        }
    }, [appState, setAppState]);

    const handleTitleChange = event => {
        setName(event.target.value);
    };

    const handleIdChange = event => {
        setSteam_id(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (!appState.is_authenticated || !appState.user_roles.includes('Admin')) {
            return alert('You must be logged in as an admin to create a new player');
        }

        // here submit the blog post to the server
        fetch(process.env.REACT_APP_API_URL + '/player', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name, steam_id}),
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.new_player_id) {
                    setName('');
                    setSteam_id('');
                } else {
                    throw new Error(data.message);
                }
            })
            .catch(error => console.error('Error:', error));
        setName('');
        setSteam_id('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit} direction="column">
            <TextField
                label="Player Id:"
                type="number"
                value={steam_id}
                onChange={handleIdChange}
                required
                className="noArrows"
            />
            <TextField
                label="Name:"
                type="text"
                value={name}
                onChange={handleTitleChange}
                required
                style={{marginLeft: '10px'}}
            />
            <Button type="submit" variant="contained" color="primary" style={{marginTop: '10px', marginLeft: '10px'}}>
                Submit
            </Button>
        </Box>
    );
}

export default CreatePlayer;
