import React, {useState, useEffect, useContext} from "react";
import {Card, CardContent, Typography, Button, TextField} from '@mui/material';
import {alpha} from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import AppStateContext from "./appStateContext";
import MenuItem from '@mui/material/MenuItem';


function UserList() {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [appState] = useContext(AppStateContext);

    useEffect(() => {
        if (!appState.is_authenticated || !appState.user_roles.includes('Admin')) {
            window.location.href = "/";
        }
        fetch(process.env.REACT_APP_API_URL + '/users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => setUsers(data))
            .catch((error) => {
                console.error('Error:', error);
            });
        fetch(process.env.REACT_APP_API_URL + '/role', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => setRoles(data))
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);


    const handleRoleChange = (event) => {
        console.log(event.target)
        setSelectedRole(event.target.value);
    };

    const handleClick = (userId) => {
        fetch(process.env.REACT_APP_API_URL + '/role/assign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({role_id: selectedRole, user_id: userId}),
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                if (!data.new_assignment_id) {
                    throw new Error(data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    label="Search for users.."
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8)}}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Role"
                    value={selectedRole}
                    onChange={handleRoleChange}
                >
                    {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                            {role.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {users
                .filter(user => user.display_name.toLowerCase().includes(searchTerm.toLowerCase())
                    || user.community_name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(user => (
                    <>
                    <Grid item xs={4} key={user.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" component="div" color="secondary">
                                    User Display Name: {user.display_name}
                                </Typography>
                                <Typography variant="h5" component="div" color="secondary">
                                    User Community Name: {user.community_name ? user.community_name: 'None'}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={() => handleClick(user.id)}>
                            Assign Role
                        </Button>
                    </Grid>
                    </>
                ))}
        </Grid>
    );
}

export default UserList;
