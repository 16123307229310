import React, {useState, useEffect, useContext} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AppStateContext from "./appStateContext";
import {Select, MenuItem, TextField, Typography, Box, Button} from '@mui/material';
import {alpha} from "@mui/material/styles";

ClassicEditor.defaultConfig = {
    toolbar: ['Heading',
        '|',
        'Bold',
        'Italic',
        'Link',
        'BulletedList',
        'NumberedList',
        'BlockQuote',
        'InsertTable',
        'Undo',
        'Redo',
        '|',
        'MediaEmbed',
        'ImageUpload'],
    mediaEmbed: {
        previewsInData: true,
        extraProviders: [
            {
                name: 'giphyEmbed',
                url: /^giphy\.com\/embed\/(.+)/,
                html: match => {
                    const id = match[1];
                    return (
                        '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                        `<iframe src="https://giphy.com/embed/${id}" ` +
                        'style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" ' +
                        'frameborder="0" class="giphy-embed" allowfullscreen></iframe>' +
                        '</div>'
                    );
                }
            },
            {
                name: 'giphyGif',
                url: /^giphy\.com\/gifs\/(.+)/,
                html: match => {
                    const id = match[1].split('-').pop();
                    console.log(match)
                    return (
                        '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                        `<iframe src="https://giphy.com/embed/${id}" ` +
                        'style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" ' +
                        'frameborder="0" class="giphy-embed" allowfullscreen></iframe>' +
                        '</div>'
                    );
                }
            },
            {
                name: 'imgur',
                url: /imgur\.com\/(a\/)?(\w+)/,
                html: match => {
                    const id = match[2];
                    return (
                        '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                        `<iframe src="https://imgur.com/${id}/embed?pub=true" ` +
                        'style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" ' +
                        'frameborder="0" scrolling="no" allowfullscreen></iframe>' +
                        '</div>'
                    );
                }
            }
        ]
    }
};

function CreateBlogPost() {
    const [title, setTitle] = useState('');
    const [appState, setAppState] = useContext(AppStateContext);
    const [data, setData] = useState('');
    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);

    useEffect(() => {
        if (!appState.is_authenticated || !appState.user_roles.includes('Admin')) {
            window.location.href = "/";
        }

        fetch(process.env.REACT_APP_API_URL + '/player')
            .then(response => response.json())
            .then(data => {
                setPlayers(data)
            })
            .catch(error => console.log(error));
    }, [appState, setAppState]);

    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setData(data);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (!appState.is_authenticated || !appState.user_roles.includes('Admin')) {
            return alert('You must be logged in as an admin to create a new post');
        }

        // here submit the blog post to the server
        fetch(process.env.REACT_APP_API_URL + '/blog', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({title, content: data, players: selectedPlayers}),
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.new_blog_id) {
                    setTitle('');
                    setData('');
                    setSelectedPlayers([]);
                } else {
                    throw new Error(data.message);
                }
            })
            .catch(error => console.error('Error:', error));
        setTitle('');
        setData('');
        setSelectedPlayers([]);
    };

    const handlePlayerSelection = selectedPlayers => {
        setSelectedPlayers(selectedPlayers);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center" style={{marginBottom: '10px'}}>
                <Typography color='primary'>
                    Title:
                </Typography>
                <TextField type='text' value={title} onChange={handleTitleChange} required
                           style={{marginLeft: '10px'}}/>
                <Typography color='primary' style={{marginLeft: '10px'}}>
                    Players:
                </Typography>
                <Typography color='secondary' sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8), marginLeft: '10px'}}>
                    <Select
                        multiple
                        onChange={e => handlePlayerSelection(e.target.value)}
                        value={selectedPlayers}
                    >
                        {players.map(player => (
                            <MenuItem key={player.id} value={player.id}>
                                {player.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Typography>
            </Box>
            <CKEditor editor={ClassicEditor} data={data} onChange={handleEditorChange}/>
            <Button variant='contained' color='primary' type='submit' style={{marginTop: '10px'}}>Submit</Button>
        </form>
    );
}

export default CreateBlogPost;
