import React from 'react';
import Button from '@mui/material/Button';

const UploadVideo = () => {
    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            await fetch(process.env.REACT_APP_API_URL + `/video`, {
                method: 'POST',
                body: formData,
                credentials: "include"
            });
        } catch (err) {
            console.error('Upload failed:', err);
        }
    }

    return (
        <div>
            <h2>Upload Video</h2>
            <input
                accept="video/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleFileSelect}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                    Select video
                </Button>
            </label>
            <br />
            <br />
            <Button variant="contained" color="primary" onClick={handleUpload}>
                Upload
            </Button>
        </div>
    )
}

export default UploadVideo;
