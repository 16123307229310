import React, {useContext, useState} from 'react';
import {Button, Card, CardContent, TextField, Typography} from '@mui/material';
import AppStateContext from "./appStateContext";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import {alpha} from "@mui/material/styles";

const VideoListContent = () => {
  const [videos, setVideos] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [appState] = useContext(AppStateContext);

  React.useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + '/video', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: "include"
      })
          .then(response => response.json())
          .then(data => setVideos(data))
          .catch((error) => {
              console.error('Error:', error);
          });
  }, []);

    return (
        <Grid container spacing={2}>
            {appState.is_authenticated && appState.user_roles.includes('Admin') && (
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" component={Link} to="/video/upload">
                        Upload new video
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    label="Search for videos.."
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8)}}
                />
            </Grid>
            {videos
                .filter(video => video.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(video => (
                    <Grid item xs={12} key={video.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Link to={`/video/${video.id}`}>
                                    <Typography variant="h5" component="div" color="secondary">
                                        {video.name}
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
}

export default VideoListContent;
