import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {Card, CardContent, Typography, Button, TextField} from '@mui/material';
import {alpha} from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import AppStateContext from "./appStateContext";


function PlayerList() {
    const [players, setPlayers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [appState] = useContext(AppStateContext);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/player')
            .then(response => response.json())
            .then(data => setPlayers(data))
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Grid container spacing={2}>
            {appState.is_authenticated && appState.user_roles.includes('Admin') && (
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" component={Link} to="/player/create">
                        Create New Player
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    label="Search for players.."
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8)}}
                />
            </Grid>
            {players
                .filter(player => player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    player.id.toString().includes(searchTerm))
                .map(player => (
                    <Grid item xs={12} key={player.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Link to={`/player/${player.id}`}>
                                    <Typography variant="h5" component="div" color="secondary">
                                        {player.name}
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
}

export default PlayerList;
