import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Card, CardContent, Container, Typography, Box, Rating} from '@mui/material';
import Grid from "@mui/material/Grid";
import AppStateContext from "./appStateContext";

function Player(props) {
    const [player, setPlayer] = useState({});
    const [reviews, setReviews] = useState()
    const [rating, setRating] = useState()
    const [newRating, setNewRating] = useState(3)
    const [appState] = useContext(AppStateContext);

    let {id} = useParams();

    const handleRatingChange = (event, newValue) => {
        fetch(process.env.REACT_APP_API_URL + `/player/${id}/rate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'rating': newValue}),
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                if (data.player_rating_id) {
                    setNewRating(newValue);
                } else {
                    throw new Error(data.message);
                }
            })
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/player/${id}`, {credentials: "include"})
            .then(response => response.json())
            .then(data => {
                setPlayer(data.player);
                setReviews(data.reviews);
                setNewRating(data.user_rating);
                let mean_rating = data.ratings.reduce((a, b) => a + b, 0) / data.ratings.length
                if (!isNaN(mean_rating)) {
                    setRating(mean_rating)
                }
                else {
                    setRating('Not yet rated!')
                }
            })
            .catch(err => console.error(err));
    }, [id]);

    return (
        <Container>
            <Box sx={{boxShadow: 1, p: 2, mt: 2, mb: 2}}>
                <Card>
                    <CardContent>
                        <Typography variant='h3' gutterBottom>
                            {player.name}
                        </Typography>
                        {appState.is_authenticated && appState.user_roles.includes('Admin') && (
                            <Typography><Rating value={newRating} onChange={handleRatingChange}/></Typography>
                        )}
                        <Typography variant='body2' color='text.secondary' gutterBottom>
                            {<em>Average Rating: {rating}</em>}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                marginTop: '10px',
                                marginBottom: '10px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <Link to={'https://www.dotabuff.com/players/' + id} target="_blank">
                                <img src={process.env.PUBLIC_URL + '/dotabuff-logo.png'} alt="dotabuff logo"
                                     style={{width: '40px', height: '40px', marginRight: '20px'}}/>
                            </Link>
                            <Link to={'https://www.stratz.com/players/' + id} target="_blank">
                                <img src={process.env.PUBLIC_URL + '/stratz-logo.png'} alt="dotabuff logo"
                                     style={{width: '40px', height: '40px', marginRight: '20px'}}/>
                            </Link>
                            <Link to={'https://www.opendota.com/players/' + id} target="_blank">
                                <img src={process.env.PUBLIC_URL + '/opendota-logo.png'} alt="dotabuff logo"
                                     style={{width: '40px', height: '40px'}}/>
                            </Link>
                        </Box>
                        <Grid container spacing={2}>
                            {reviews && (
                                <Grid item xs={12}>
                                    <Typography variant='h5' gutterBottom>
                                        Reviews
                                    </Typography>
                                </Grid>)}
                            {reviews && reviews.map(review => (
                                <Grid item xs={12} key={review.id}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Link to={`/blog/${review.id}`}>
                                                <Typography variant="h5" component="div" color="secondary">
                                                    {review.title}
                                                </Typography>
                                            </Link>
                                            <Typography color="text.secondary">
                                                Author: {review.author_name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Posted: {review.date}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
}

export default Player;
