import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {Card, CardContent, Typography, Button, TextField} from '@mui/material';
import {alpha} from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import AppStateContext from "./appStateContext";


function RoleList() {
    const [roles, setRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [appState] = useContext(AppStateContext);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/role', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => setRoles(data))
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Grid container spacing={2}>
            {appState.is_authenticated && appState.user_roles.includes('Admin') && (
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" component={Link} to="/role/create">
                        Create New Role
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    label="Search for roles.."
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8)}}
                />
            </Grid>
            {roles
                .filter(role => role.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(role => (
                    <Grid item xs={12} key={role.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" component="div" color="secondary">
                                    {role.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
}

export default RoleList;
