import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {Card, CardContent, Typography, Button, TextField} from '@mui/material';
import Grid from '@mui/material/Grid';
import AppStateContext from "./appStateContext";
import {alpha} from "@mui/material/styles";

function BlogList() {
    const [posts, setPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [appState] = useContext(AppStateContext);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/blog')
            .then(response => response.json())
            .then(data => setPosts(data.reverse()))
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Grid container spacing={2}>
            {appState.is_authenticated && appState.user_roles.includes('Admin') && (
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" component={Link} to="/blog/create">
                        Create New Post
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    label="Search for posts.."
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    sx={{bgcolor: theme => alpha(theme.palette.primary.main, 0.8)}}
                />
            </Grid>
            {posts
                .filter(post => post.title.toLowerCase().includes(searchTerm.toLowerCase())
                    || post.author.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(post => (
                <Grid item xs={12} key={post.id}>
                    <Card variant="outlined">
                        <CardContent>
                            <Link to={`/blog/${post.id}`}>
                                <Typography variant="h5" component="div" color="secondary">
                                    {post.title}
                                </Typography>
                            </Link>
                            <Typography color="text.secondary">
                                Author: {post.author}
                            </Typography>
                            <Typography color="text.secondary">
                                Posted: {post.date}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default BlogList;
