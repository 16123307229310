import {useState, useEffect} from 'react';
import './App.css';
import {AppBar, Toolbar, Box, Button, Container, ThemeProvider, BottomNavigation, BottomNavigationAction} from '@mui/material';
import {BrowserRouter as Router, Route, Routes, Link} from "react-router-dom";

import About from "./About"
import AppTheme from "./AppTheme";
import Contact from "./Contact";
import Home from "./Home";
import BlogList from "./BlogList";
import BlogPost from "./BlogPost";
import PlayerList from "./PlayerList";
import Player from "./Player";
import CreatePlayer from "./CreatePlayer";
import LogoutButton from "./LogoutButton";
import AppStateContext from "./appStateContext";
import CreateBlogPost from "./CreateBlogPost";
import UserList from "./UserList";
import CreateRole from "./CreateRole";
import RoleList from "./RoleList";
import VideoList from "./VideoList";
import Video from "./Video";
import UploadVideo from "./UploadVideo";

function App() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [appState, setAppState] = useState({
        display_name: null
    });

    useEffect(() => {
        const scrollListener = () => {
            window.scrollY > 60 ? setIsScrolled(true) : setIsScrolled(false);
        }
        window.addEventListener("scroll", scrollListener);
        const checkAuthenticatedStatus = () => {
            if (!appState.is_authenticated) {
                fetch(process.env.REACT_APP_API_URL + '/user', {credentials: "include"})
                    .then(response => response.json())
                    .then(data => {
                        setAppState((prevState) => ({
                            ...prevState,
                            display_name: data.display_name,
                            is_authenticated: data.is_authenticated,
                            user_roles: data.user_roles
                        }));
                    })
                    .catch(error => {
                        console.error('Authentication validation failed:', error);
                    });
            }
        };
        checkAuthenticatedStatus();
        return () => {
            window.removeEventListener("scroll", scrollListener);
        }
    }, []);

    return (
        <AppStateContext.Provider value={[appState, setAppState]}>
            <Router>
                <ThemeProvider theme={AppTheme}>
                    <AppBar position='sticky'
                            top='0'
                            color={isScrolled ? 'primary' : 'transparent'}
                            sx={{
                                bgcolor: isScrolled ? 'primary' : 'transparent',
                                boxShadow: isScrolled ? '1px 1px 5px rgb(0 0 0 / 20%)' : 'none',
                                transition: '0.0s'
                            }}>
                        <Toolbar variant="dense" sx={{justifyContent: 'center'}}>
                            <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/">
                                Home
                            </Button>
                            <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/blog">
                                Blog
                            </Button>
                            <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/player">
                                Player Database
                            </Button>
                            <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/video">
                                Videos
                            </Button>
                            {appState.is_authenticated ? (
                                <>
                                    <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/user">
                                        Users
                                    </Button>
                                    <Button color={isScrolled ? 'inherit' : 'primary'} component={Link} to="/role">
                                        Roles
                                    </Button>
                                    <Box sx={{marginLeft: 'auto'}}>
                                        <LogoutButton color={isScrolled ? 'inherit' : 'primary'}
                                                      onLogout={() => setAppState({
                                                          ...appState,
                                                          is_authenticated: false,
                                                          display_name: "",
                                                          user_roles: []
                                                      })}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <Box sx={{marginLeft: 'auto'}}>
                                    <Button color={isScrolled ? 'inherit' : 'primary'}
                                            onClick={() => {
                                                window.location.href = process.env.REACT_APP_API_URL + '/login';
                                            }}
                                    >
                                        <img src={require("./SteamLoginLogo.png")} alt="Log In With Steam"/>
                                    </Button>
                                </Box>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="lg" sx={{minHeight: '100vh'}}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/blog" element={<BlogList/>}/>
                            <Route path="/blog/:id" element={<BlogPost/>}/>
                            <Route path="/blog/create" element={<CreateBlogPost/>}/>
                            <Route path="/player" element={<PlayerList/>}/>
                            <Route path="/player/:id" element={<Player/>}/>
                            <Route path="/player/create" element={<CreatePlayer/>}/>
                            <Route path="/user" element={<UserList/>}/>
                            <Route path="/role" element={<RoleList/>}/>
                            <Route path="/role/create" element={<CreateRole/>}/>
                            <Route path="/video" element={<VideoList/>}/>
                            <Route path="/video/:id" element={<Video/>}/>
                            <Route path="/video/upload" element={<UploadVideo/>}/>
                        </Routes>
                    </Container>
                    <BottomNavigation
                        showLabels
                        sx={{
                            width: '100%',
                            bottom: 0,
                            backgroundColor: 'primary.main'
                        }}
                        color='primary'
                    >
                        <BottomNavigationAction label="About" component={Link} to="/about"
                                                onClick={() => window.scrollTo(0, 0)}/>
                        <BottomNavigationAction label="Contact" component={Link} to="/contact"
                                                onClick={() => window.scrollTo(0, 0)}/>
                    </BottomNavigation>
                </ThemeProvider>
            </Router>
        </AppStateContext.Provider>
    );
}

export default App;
