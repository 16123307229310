import React, { useEffect, useRef } from 'react';
import { Container } from '@mui/material';

function Home({ channel = "viragedota" }) {
    const embedRef = useRef(null);
    const embedCreated = useRef(false);

    useEffect(() => {
        const createEmbed = () => {
            if (embedRef.current && window.Twitch && !embedCreated.current) {
                new window.Twitch.Embed(embedRef.current, {
                    width: '100%',
                    height: '100%',
                    channel,
                });
                embedCreated.current = true;
            }
        };

        if (!window.Twitch) {
            const script = document.createElement("script");
            script.src = "https://embed.twitch.tv/embed/v1.js";
            script.async = true;
            script.onload = createEmbed;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        } else {
            createEmbed();
        }
    }, []);

    return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '55vh' }}>
            <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' /* 16:9 aspect ratio */ }}>
                <div id="twitch-embed" ref={embedRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
            </div>
        </Container>
    );
}

export default Home;
