import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardContent, Container, Typography, Box, CardMedia} from '@mui/material';

function Video(props) {
    const [video, setVideo] = useState({});

    let {id} = useParams();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/video/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                setVideo(data);
            })
            .catch(err => console.error(err));
    }, [id]);

    return (
        <Container>
            <Box sx={{boxShadow: 1, p: 2, mt: 2, mb: 2}}>
                <Card>
                    <CardContent>
                        <Typography variant='h3' gutterBottom>
                            {video.name}
                        </Typography>
                        <CardMedia
                            component="video"
                            src={video.url}
                            title={video.name}
                            controls
                            controlslist="nodownload"
                        />
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
}

export default Video;
