import React, {useContext, useState} from 'react';
import {Button, TextField, Box, Typography} from '@mui/material';
import AppStateContext from "./appStateContext";

function Contact() {
    const receiver = "viragebarrage@gmail.com";
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [appState] = useContext(AppStateContext);

    const sendEmail = (e) => {
        e.preventDefault();
        window.open(`mailto:${receiver}?subject=${subject}&body=${message}`, "_blank");
        resetForm();
    }

    const resetForm = () => {
        setSubject("");
        setMessage("");
    }

    return (
        <form onSubmit={sendEmail}>
            <Box sx={{display: "flex", flexDirection: "column", gap: "1em"}}>
                <TextField required value={subject} onChange={e => setSubject(e.target.value)} id="outlined-basic"
                           label="Subject" variant="outlined"/>
                <TextField required value={message} onChange={e => setMessage(e.target.value)}
                           id="outlined-multiline-static" label="Message" multiline rows={4} variant="outlined"/>
                {appState.is_authenticated ?
                    <Button type="submit" variant="contained">
                        Send
                    </Button> :
                    <Typography color='primary'>You must be signed in to send a message</Typography>
                }
            </Box>
        </form>
    )
}

export default Contact;
